import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const appUrl = url => (process.env.NODE_ENV == 'development' ? "http://localhost:4000" : "") + url;

const FeatureRow = 
  ({children}) =>
    <div className="flex sm:flex-row flex-col sm:p-4 space-y-8 sm:space-y-0 sm:space-x-6 mb-8 sm:mb-0 my-2 sm:my-0">
      {children}
    </div>;

const Divider = 
  ({}) => <div className="hidden sm:block border-b border-gray-300 h-px mx-auto my-6 max-w-xl" />;

const Feature =
  ({title, children}) => 
    <div className="flex w-full sm:w-[528px]">
      <svg className="flex-shrink-0 h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" 
           stroke="currentColor" aria-hidden="true">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
      </svg>
      <div className="ml-3">
        <dt className="text-xl font-bold leading-6 text-gray-900">
          {title}
        </dt>
        <dd className="mt-2 text-lg text-gray-500">
          {children}
        </dd>
      </div>
    </div>;
  
const InBlue = ({className, children, ...props}) => 
  <span className={"text-blue-700 " + className} {...props}>
    {children}
  </span>;

class RootIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location} isPage={false}>
        <Helmet title={`The Ultimate Nutritional Planner | ${siteTitle}`} />
        <div className="bg-gray-100">
          <div className="relative overflow-hidden">
            <div className="relative pt-6 pb-8 sm:pb-12">
              <div className="mt-2 mx-auto max-w-4xl px-4 sm:mt-4 sm:px-6">
                <div className="text-center">
                  <h1 className="text-3xl sm:text-5xl font-extrabold text-gray-900">
                    Visually design custom meal plans that hit your <InBlue>exact macro targets.</InBlue>
                  </h1>
                  <p className="mt-2 mx-auto text-sm text-gray-400 sm:mt-3 sm:text-[26px] sm:leading-tight">
                    Then let ForkPlan do the rest, so you can <InBlue className="font-bold">shop</InBlue>,&nbsp;
                    <InBlue className="font-bold">prep</InBlue>, and <InBlue className="font-bold">eat</InBlue> with total
                    confidence – all while effortlessly achieving your health goals.
                  </p>
                </div>
              </div>
            </div>
            <div className="relative">
              <div className="absolute inset-0 flex flex-col" aria-hidden="true">
                <div className="flex-1" />
                <div className="flex-1 w-full bg-gray-800" />
              </div>
              <div className="max-w-5xl mx-auto px-8 sm:px-6">
                <img className="hidden sm:block relative rounded-lg shadow-lg" src="/content/img/plan-screenshot.png" alt="App screenshot" />
                <img className="sm:hidden relative rounded-lg shadow-lg" src="/content/img/plan-screenshot-mobile.png" alt="App screenshot" />
              </div>
            </div>
          </div>
          <div className="bg-gray-800">
            <div className="max-w-4xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
              <div>
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-50 sm:text-4xl">
                  <span className="block">Ready to start <strong>designing</strong> your meal plans?</span>
                </h2>
                <p className="mt-4 text-lg leading-6 text-blue-200 max-w-xl">
                  Join the ForkPlan private beta program now for $29. As a founding member, you will get lifetime access to the product.
                </p>
              </div>

              <div className="mt-8 lex lg:mt-0 lg:flex-shrink-0">
                <div className="inline-flex rounded-md shadow">
                  <a href={appUrl("/account/join")} className="inline-flex items-center justify-center px-5 py-3 border 
                    border-transparent font-extrabold text-xl sm:text-2xl rounded-md text-gray-50 bg-blue-600 
                  hover:bg-blue-700">
                    Join Now
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white mx-auto">
            <div className="max-w-6xl mx-auto py-12 px-4 sm:px-6 lg:py-18 lg:px-8">
              <div className="max-w-3xl mx-auto text-center">
                <h2 className="text-3xl font-extrabold text-gray-900">
                  Hit Your Macros Every Time. No Counting Required.
                </h2>
                <p className="mt-2 text-lg text-gray-500 leading-6">
                  Free yourself from constantly tracking your macros. Instead, design plans that are perfect for you 
                  and then let ForkPlan take care of the rest.
                </p>
              </div>
              <dl className="mt-12">
                <FeatureRow>
                  <Feature title="Fluid Meal Plan Designer">
                    Leave rigid templates, inflexible apps, and tedious planning sessions behind. ForkPlan’s fluid meal plan
                    designer makes meal planning quick and easy. Build fully custom plans from recipes and ingredients in our...
                  </Feature>
                  <Feature title="Curated Ingredient Library">
                    Stop suffering through massive food databases riddled with low quality data and unhealthy processed foods.
                    ForkPlan’s curated library has all the real ingredients you actually want to eat. Use them to build fully 
                    custom plans and...
                  </Feature>
                </FeatureRow>
                <Divider />
                <FeatureRow>
                  <Feature title="Reusable Recipes">
                    Create recipes once and use them in your plans, again and again. From simple to complex, ForkPlan handles it
                    all, so you never have to stick to bland food to make the math easier. Add recipes 
                    to your plans and take full advantage of...
                  </Feature>
                  <Feature title="Precision Scaling & Instant Macro Calculation">
                    Watch macros update instantly as you tweak your recipes and plans. Add ingredients, adjust amounts, and drag 
                    food around...without ever breaking your creative flow for math. Once you’ve perfected your plan, it’s time 
                    to shop with...
                  </Feature>
                </FeatureRow>
                <Divider />
                <FeatureRow>
                  <Feature title="Auto-Generated Grocery Lists">
                    ForkPlan instantly generates grocery lists for your plans, so shopping is a breeze – whether that’s at the 
                    store or in the next browser tab. You’ll always buy only the ingredients you need so you’ll be fully prepared 
                    for...
                  </Feature>
                  <Feature title="No Sweat (and No Math) Cooking">
                    Batch prep your whole plan at once or cook any combination of one or more meals at a time. Ingredients are 
                    always scaled to exact amounts so you never have to do math in the kitchen. Once you’re done cooking, it’s
                    time to...
                  </Feature>
                </FeatureRow>
                <Divider />
                <FeatureRow>
                  <Feature title="Actually Enjoy Your Food">
                    See your plans come to life and enjoy every meal with full confidence that your macros are 100% handled. 
                    Whether it’s a meal you just cooked or one you previously prepared, never worry about eating too much 
                    or too little. Just enjoy and...
                  </Feature>
                  <Feature title="Achieve Your Goals Without the Stress">
                    Free yourself from constant tracking and worrying about your numbers. Just relax, trust the system, 
                    and let the results come in naturally – whether you’re trying to change your body composition, improve your
                    energy, or just get healthier.
                  </Feature>
                </FeatureRow>
              </dl>
            </div>
            <div className="mx-auto text-center pb-8">
              <a href={appUrl("/account/join")} className="mx-auto inline-flex items-center justify-center px-5 py-3 border border-transparent font-bold
                                                           text-xl sm:text-lg rounded-md text-gray-50 bg-blue-600 hover:bg-blue-700">
                Join ForkPlan Beta Now
              </a>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
